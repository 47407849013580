<template>
    <div class="window not-found">
      <div class="title-bar">
        <button class="close"></button>
        <h1 class="title">404 - Página Não Encontrada</h1>
      </div>
      <div class="window-pane">
        <p>Desculpe, a página que você está procurando não existe.</p>
        <router-link to="/" class="btn">Voltar para a página inicial</router-link>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'NotFoundPage'
};
</script>
  
  <style scoped>
  .not-found {
    width: 600px;
    margin: 50px auto;
  }
  .window {
    padding: 15px;
  }
  </style>