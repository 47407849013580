<template>
<div class="window dashboard-page">
  <div class="title-bar">
    <h1 class="title" v-if="displayName">Olá, {{ displayName }}</h1>
  </div>
  <div class="separator"></div>

  <div class="window-pane">
    <br>
    <div class="file-upload"
         @dragover.prevent="handleDragOver"
         @dragenter.prevent="handleDragEnter"
         @dragleave.prevent="handleDragLeave"
         @drop.prevent="handleDrop"
         :class="{ 'drag-over': dragOver }">
      <p v-if="!dragOver">Arraste e solte seu arquivo CSV aqui</p>
      <p v-else>Solte seu arquivo CSV aqui</p>
    </div>
    <ul>
      <li v-for="(item, index) in csvFiles" :key="index">
        {{ item.fileName }}
        <button class="btn" @click="viewCsvFile(item.fileName)">Visualizar</button>
      </li>
    </ul>
    <br>
    <button class="btn" @click="clearLocalStorage">Limpar Armazenamento Local</button>
  </div>
</div>
</template>

<script>
import { processCsv } from './CsvProcessor';

export default {
  name: 'DashboardPage',
  data() {
    return {
      displayName: '',
      dragOver: false,
      csvFiles: []
    };
  },
  mounted() {
    this.displayName = localStorage.getItem('displayName');
    if (!this.displayName) {
      this.$router.push('/login');
    }
    const storedCsvFiles = JSON.parse(localStorage.getItem('csvFiles'));
    if (storedCsvFiles) {
      this.csvFiles = storedCsvFiles;
    }
  },
  methods: {
    handleDrop(event) {
      event.preventDefault();
      this.dragOver = false;
      const file = event.dataTransfer.files[0];
      if (file && file.type === 'text/csv') {
        this.processCSV(file);
      } else {
        console.error('Please drop a valid CSV file.');
      }
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragEnter(event) {
      event.preventDefault();
      this.dragOver = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.dragOver = false;
    },
    async processCSV(file) {
      try {
        const { key } = await processCsv(file);
        this.csvFiles.push({ fileName: key });
        localStorage.setItem('csvFiles', JSON.stringify(this.csvFiles));
      } catch (error) {
        console.error('Erro ao processar o CSV:', error.message);
      }
    },
    clearLocalStorage() {
      const displayName = localStorage.getItem('displayName');
      localStorage.clear();
      if (displayName) {
        localStorage.setItem('displayName', displayName);
      }
      this.csvFiles = [];
      console.log('Armazenamento local limpo.');
    },
    viewCsvFile(fileName) {
      this.$router.push({ name: 'view', params: { fileName } });
    }
  }
};
</script>

<style scoped>
.dashboard-page {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.file-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.file-upload.drag-over {
  background-color: #f0f0f0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}
</style>
