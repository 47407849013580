import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCL4sxg_gBTON4YUJiQhqSdIFzH6AoP0VQ",
  authDomain: "supermei-auth.firebaseapp.com",
  projectId: "supermei-auth",
  storageBucket: "supermei-auth.appspot.com",
  messagingSenderId: "791775540437",
  appId: "1:791775540437:web:5f22e129d7354fdb651e5c"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

const isAuthenticated = () => {
  return new Promise((resolve) => {
    onAuthStateChanged(auth, (user) => {
      resolve(!!user);
    });
  });
};

export { firebaseApp, auth, isAuthenticated };