<template>
  <div class="window">
    <div class="title-bar">
      <button aria-label="Close" class="close"></button>
      <h1 class="title">Dados Importados</h1>
      <button aria-label="Resize" class="resize"></button>
  </div>
  <div class="table-container">
    <table v-if="csvData.length > 0" class="custom-table">
        <thead>
          <tr>
            <th>username</th>
            <th>fullname</th>
            <th>email</th>
            <th>institution</th>
            <th>department</th>
            <th>cohort1</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in csvData" :key="index">
            <td>{{ entry.username }}</td>
            <td>{{ entry.fullname }}</td>
            <td>{{ entry.email }}</td>
            <td>{{ entry.institution }}</td>
            <td>{{ entry.department }}</td>
            <td>{{ entry.cohort1 }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>Nenhum dado CSV carregado ainda.</p>
      </div>
  </div>
      <!-- Botão para navegar para a página de edição -->
      <button class="btn" @click="goToEditorPage(0)">Editor Individual</button>
      <button class="btn" @click="goToBulkPage(0)">Editor em Massa</button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ViewPage',
    props: {
      fileName: {
        type: String,
        required: true
      }
    },        
    data() {
      return {
        csvData: []
      };
    },
    mounted() {
      const fileName = this.$route.params.fileName;
      const csvDataString = localStorage.getItem(fileName);
    
      if (csvDataString) {
        this.csvData = JSON.parse(csvDataString);
      } else {
        console.warn(`Nenhum dado encontrado para o arquivo ${fileName} no localStorage.`);
      }
    },
    methods: {
      goToEditorPage(index) {
        const fileName = this.$route.params.fileName;
       this.$router.push({ name: 'editor', params: { fileName: fileName, entryIndex: index } });
      },
      goToBulkPage(index) {
        const fileName = this.$route.params.fileName;
       this.$router.push({ name: 'bulk', params: { fileName: fileName, entryIndex: index } });
      }
    }
  };
  </script>

<style scoped>
.window {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.btn {
  margin-top: 20px;
}
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
</style>  