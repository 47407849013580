import { createRouter, createWebHistory } from 'vue-router';
import { isAuthenticated } from './firebaseConfig';
import LoginPage from './components/LoginPage.vue';
import DashboardPage from './components/DashboardPage.vue';
import ViewPage from './components/ViewPage.vue';
import BulkPage from './components/BulkPage.vue';
import EditorPage from './components/EditorPage.vue';
import SendPage from './components/SendPage.vue';
import NotFoundPage from './components/NotFoundPage.vue';

const routes = [
  { path: '/', component: LoginPage },

  { 
    path: '/dashboard', 
    name: 'dashboard', 
    component: DashboardPage,
    meta: { requiresAuth: true }
  },

  { 
    path: '/view', 
    name: 'viewNoParams', 
    component: ViewPage,
    meta: { requiresAuth: true }
  },
  { 
    path: '/view/:fileName', 
    name: 'view', 
    component: ViewPage, 
    props: true,
    meta: { requiresAuth: true }
  },

  { 
    path: '/editor', 
    name: 'editorNoParams', 
    component: EditorPage,
    meta: { requiresAuth: true }
  },
  { 
    path: '/editor/:fileName/:entryIndex', 
    name: 'editor', 
    component: EditorPage, 
    props: true,
    meta: { requiresAuth: true }
  },

  { 
    path: '/bulk', 
    name: 'bulkNoParams', 
    component: BulkPage,
    meta: { requiresAuth: true }
  },
  { 
    path: '/bulk/:fileName/:entryIndex', 
    name: 'bulk', 
    component: BulkPage, 
    props: true,
    meta: { requiresAuth: true }
  },

  { 
    path: '/send', 
    name: 'sendNoParams', 
    component: SendPage,
    meta: { requiresAuth: true }
  },
  { 
    path: '/send/:fileName', 
    name: 'send', 
    component: SendPage, 
    props: true,
    meta: { requiresAuth: true }
  },

  { path: '/:pathMatch(.*)*', name: 'notFound', component: NotFoundPage }
];

const router = createRouter({
  mode: history,
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const userAuthenticated = await isAuthenticated();
    if (!userAuthenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;