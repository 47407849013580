<template>
  <div>
    <div class="window">
      <div class="title-bar">
        <button aria-label="Close" class="close"></button>
        <h1 class="title">Estudante</h1>
        <button aria-label="Resize" class="resize"></button>
      </div>
      
      <div v-if="currentEntry">
        <span>{{ currentEntry.fullname }} | </span>
        <span>{{ currentEntry.department }} | </span>
        <span>{{ currentEntry.cohort1 }}</span>
      </div>

      <div class="separator"></div>

      <div class="window-pane">
        <div v-if="currentEntry">
          <h2>Disciplinas Matriculadas</h2>
          <section class="field-row" style="justify-content: center">
            <button class="btn" @click="toggleDivAndModal">Adicionar</button>
            <button class="btn" @click="viewCsvFile(currentEntry)">Visualizar</button>
          </section>
          <table>
            <thead>
              <tr>
                <th>Disciplina</th>
                <th>Data de Início</th>
                <th>Duração (dias)</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(index, idx) in getCourseIndices()" :key="index">
                <td>{{ currentEntry[`course${index}`] }}</td>
                <td>{{ currentEntry[`enroltimestart${index}`] }}</td>
                <td>{{ currentEntry[`enrolperiod${index}`] }}</td>
                <td class="btn-remove">
                  <button class="btn-custom" @click="removerDisciplina(idx + 1)" disable>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.17065 4C9.58249 2.83481 10.6937 2 11.9999 2C13.3062 2 14.4174 2.83481 14.8292 4" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M20.5 6H3.49988" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M18.3735 15.3991C18.1965 18.054 18.108 19.3815 17.243 20.1907C16.378 21 15.0476 21 12.3868 21H11.6134C8.9526 21 7.6222 21 6.75719 20.1907C5.89218 19.3815 5.80368 18.054 5.62669 15.3991L5.16675 8.5M18.8334 8.5L18.6334 11.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M9.5 11L10 16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M14.5 11L14 16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                  </button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <section>
        <button class="btn" @click="previousItem" :disabled="currentIndex === 0">Voltar</button>
        <button  class="btn" @click="nextItem" :disabled="currentIndex === csvData.length - 1">Avançar</button>
      </section>
  </div>
  </div>
  <div v-show="showDiv" class="modal-background"></div>
  <div v-if="showModal" class="modal modal-dialog outer-border" style="width: 30rem;">
    <div class="inner-border center">
    <div class="modal-contents">
      <h1 class="modal-text">Adicionar</h1>
          <div style="padding-left: 20px;">
            <div class="field-row">
              <label for="discipline">Disciplina </label>
              <select v-model="newCourse.discipline" id="discipline">
                <option value="Disciplina 1">Disciplina 1</option>
                <option value="Disciplina 2">Disciplina 2</option>
                <option value="Disciplina 3">Disciplina 3</option>
                <option value="Disciplina 4">Disciplina 4</option>
                <option value="Disciplina 5">Disciplina 5</option>
              </select>
            </div>
            <div class="field-row">
              <label for="startDate">Data de Início </label>
              <input type="date" v-model="newCourse.startDate" @change="formatDate" id="startDate" />
            </div>
            <div class="field-row">
              <label for="duration">Duração da Inscrição (dias) </label>
              <input type="range" v-model="newCourse.duration" id="duration" min="0" max="365" />
              <span>{{ newCourse.duration }} dias</span>
            </div>
            <section class="field-row" style="justify-content: flex-end">
              <button class="btn" @click="cancel">Cancelar</button>
              <button class="btn" type="submit" @click="addDiscipline">Adicionar</button>
            </section>
          </div>
        </div>
        </div>
      </div>


</template>
  
<script>
import { format } from 'date-fns';

export default {
  name: 'EditorPage',
  data() {
    return {
      csvData: [],
      currentIndex: 0,
      currentEntry: null,
      showModal: false,
      showDiv: false,
      newCourse: {
        discipline: '',
        startDate: '',
        duration: 0
      }
    };
  },
  
  mounted() {
    const fileName = this.$route.params.fileName;
    const csvDataString = localStorage.getItem(fileName);
  
    if (csvDataString) {
      this.csvData = JSON.parse(csvDataString);
      this.currentEntry = this.csvData[this.currentIndex];
    }
  },
  
  methods: {
    cancel() {
      this.showModal = false;
      this.showDiv = false;
      this.newCourse = {
        discipline: '',
        startDate: '',
        duration: 0
      };
    },

    toggleDivAndModal() {
      this.showDiv = true;
      this.showModal = true;
    },

    addDiscipline() {

      if (!this.newCourse.discipline || !this.newCourse.startDate || !this.newCourse.duration) {
        alert('Todos os campos do formulário devem ser preenchidos.');
        return;
      }

      const courseExists = Object.values(this.currentEntry).some(value => value === this.newCourse.discipline);
      if (courseExists) {
        alert('Essa disciplina já foi adicionada.');
        return;
      }

      const newIndex = this.getCourseIndices().length + 1;
      this.currentEntry[`course${newIndex}`] = this.newCourse.discipline;
      this.currentEntry[`enroltimestart${newIndex}`] = format(new Date(this.newCourse.startDate), 'dd-MM-yyyy');
      this.currentEntry[`enrolperiod${newIndex}`] = this.newCourse.duration;
      this.currentEntry[`group${newIndex}`] = this.currentEntry.cohort1;

      const fileName = this.$route.params.fileName;
      this.csvData[this.currentIndex] = this.currentEntry;
      localStorage.setItem(fileName, JSON.stringify(this.csvData));

      this.cancel();
    },
  
    getCourseIndices() {
      if (this.currentEntry) {
        return Object.keys(this.currentEntry)
          .filter(key => key.startsWith('course'))
          .map(key => key.slice(6));
      }
      return [];
    },
  
    removerDisciplina(index) {
  const key = `course${index}`;
  if (this.currentEntry[key]) {
    // Remove todos os campos relacionados à disciplina
    delete this.currentEntry[key];
    delete this.currentEntry[`enroltimestart${index}`];
    delete this.currentEntry[`enrolperiod${index}`];
    delete this.currentEntry[`group${index}`];

    console.log('Disciplina removida:', key);

    const fileName = this.$route.params.fileName;
    this.csvData[this.currentIndex] = this.currentEntry;
    localStorage.setItem(fileName, JSON.stringify(this.csvData));
  }
},

    nextItem() {
      if (this.currentIndex < this.csvData.length - 1) {
        this.currentIndex++;
        this.currentEntry = this.csvData[this.currentIndex];
      }
    },
  
    previousItem() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.currentEntry = this.csvData[this.currentIndex];
      }
    },

  viewCsvFile() {
    const fileName = this.$route.params.fileName;
    this.$router.push({ name: 'send', params: { fileName: fileName } });
  }

  }
};
</script>




<style scoped>
.window {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.btn {
  margin: 0px 0px 10px 10px;
}
.btn-remove {
  max-width: 70px;
}
.btn-custom {
  border: none;
  background-color: transparent;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 9000;
}
.modal-content {
  max-width: 100%;
  overflow: hidden;
}
input[type="date"],
input[type="range"],
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
input[type="range"] {
  width: calc(100% - 40px);
  margin-right: 10px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px);
  z-index: 2000;
}

</style>  