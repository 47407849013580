<template>
    <div>
      <div class="window">
        <div class="title-bar">
          <button aria-label="Close" class="close"></button>
          <h1 class="title">Edição em Massa</h1>
          <button aria-label="Resize" class="resize"></button>
        </div>
  
        <div class="separator"></div>
  
        <div class="window-pane">
          <h2>Disciplinas a Adicionar</h2>
          <section class="field-row" style="justify-content: center">
            <button class="btn" @click="toggleDivAndModal">Adicionar Disciplinas</button>
            <button class="btn" @click="viewCsvFile">Visualizar</button>
          </section>
  
          <div v-if="showModal" class="modal modal-dialog outer-border" style="width: 40rem;">
            <div class="inner-border center">
              <div class="modal-contents">
                <h1 class="modal-text">Adicionar Disciplinas</h1>
                <div style="padding-left: 20px;">
                  <div class="field-row">
                    <label for="disciplines">Disciplinas (separadas por vírgula)</label>
                    <textarea v-model="newCourses.disciplines" id="disciplines" rows="4" cols="50"></textarea>
                  </div>
                  <div class="field-row">
                    <label for="startDate">Data de Início</label>
                    <input type="date" v-model="newCourses.startDate" id="startDate" />
                  </div>
                  <div class="field-row">
                    <label for="durationFirst">Duração da Primeira Inscrição (dias)</label>
                    <input type="range" v-model="newCourses.durationFirst" id="durationFirst" min="0" max="365" />
                    <span>{{ newCourses.durationFirst }} dias</span>
                  </div>
                  <div class="field-row">
                    <label for="durationOthers">Duração das Outras Inscrições (dias)</label>
                    <input type="range" v-model="newCourses.durationOthers" id="durationOthers" min="0" max="365" />
                    <span>{{ newCourses.durationOthers }} dias</span>
                  </div>
                  <section class="field-row" style="justify-content: flex-end">
                    <button class="btn" @click="cancel">Cancelar</button>
                    <button class="btn" type="submit" @click="addDisciplines">Adicionar</button>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div v-show="showDiv" class="modal-background"></div>
    </div>
  </template>
  
  <script>
  import { format } from 'date-fns';
  
  export default {
    name: 'BulkPage',
    data() {
      return {
        csvData: [],
        showModal: false,
        showDiv: false,
        newCourses: {
          disciplines: '',
          startDate: '',
          durationFirst: 62,
          durationOthers: 30,
        },
      };
    },
  
    mounted() {
      const fileName = this.$route.params.fileName;
      const csvDataString = localStorage.getItem(fileName);
  
      if (csvDataString) {
        this.csvData = JSON.parse(csvDataString);
      }
    },
  
    methods: {
      cancel() {
        this.showModal = false;
        this.showDiv = false;
        this.newCourses = {
          disciplines: '',
          startDate: '',
          durationFirst: 0,
          durationOthers: 0,
        };
      },
  
      toggleDivAndModal() {
        this.showDiv = true;
        this.showModal = true;
      },
  
      addDisciplines() {
        const { disciplines, startDate, durationFirst, durationOthers } = this.newCourses;
  
        if (!disciplines || !startDate || !durationFirst || !durationOthers) {
          alert('Todos os campos do formulário devem ser preenchidos.');
          return;
        }
  
        const disciplinesArray = disciplines.split(',').map(d => d.trim());
        const startDateObject = new Date(startDate);
  
        this.csvData.forEach((entry, index) => {
          disciplinesArray.forEach((discipline, idx) => {
            const courseIndex = this.getCourseIndices(entry).length + 1;
            entry[`course${courseIndex}`] = discipline;
            entry[`enroltimestart${courseIndex}`] = format(new Date(startDateObject.getTime() + (idx * 31 * 24 * 60 * 60 * 1000)), 'dd-MM-yyyy');
            entry[`enrolperiod${courseIndex}`] = idx === 0 ? durationFirst : durationOthers;
            entry[`group${courseIndex}`] = entry.cohort1;
          });
  
          this.csvData[index] = entry;
        });
  
        const fileName = this.$route.params.fileName;
        localStorage.setItem(fileName, JSON.stringify(this.csvData));
  
        this.cancel();
      },
  
      getCourseIndices(entry) {
        return Object.keys(entry)
          .filter(key => key.startsWith('course'))
          .map(key => key.slice(6));
      },
  
      viewCsvFile() {
        const fileName = this.$route.params.fileName;
        this.$router.push({ name: 'send', params: { fileName: fileName } });
      }
    }
  };
  </script>
  
  
  <style scoped>
.window {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
.btn {
  margin: 0px 0px 10px 10px;
}
.btn-remove {
  max-width: 70px;
}
.btn-custom {
  border: none;
  background-color: transparent;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 9000;
}
.modal-contents {
  max-width: 100%;
  overflow: hidden;
  text-align: left !important;
}
input[type="date"],
input[type="range"],
input[type="textarea"],
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
input[type="range"] {
  width: calc(100% - 40px);
  margin-right: 10px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px);
  z-index: 2000;
}

</style>  