import { Buffer } from 'buffer';
import iconv from 'iconv-lite';

const processCsv = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const buffer = Buffer.from(arrayBuffer);
      const csvData = iconv.decode(buffer, 'windows-1252');
      const parsedData = parseCsvData(csvData);
      const key = `csv_${Date.now()}`; 
      storeData(key, parsedData);
      resolve({ key, data: parsedData });
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};

const parseCsvData = (csvData) => {
  const lines = csvData.split('\n').filter(line => line.trim().length > 0);
  if (lines.length === 0) return [];

  const firstLine = lines[0];
  const delimiter = firstLine.includes(';') ? ';' : ',';

  const headers = firstLine.split(delimiter).map(header => header.trim());
  const data = [];

  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(delimiter).map(value => value.trim());
    let entry = {};

    for (let j = 0; j < headers.length; j++) {
      switch (headers[j]) {
        case 'Nome Aluno':
          if (!entry.username) {
            entry.username = values[j].replace(/[.-]/g, '');
          } else {
            entry.fullname = values[j];
          }
          break;
        case 'E-mail Aluno':
          entry.email = values[j];
          break;
        case 'Nome Unidade Ensino':
          entry.institution = values[j];
          break;
        case 'Nome Curso':
          entry.department = values[j];
          break;
        case 'Identificador Turma':
        case 'Identidicador Turma':
          entry.cohort1 = values[j];
          break;
        default:
          break;
      }
    }
    data.push(entry);
  }
  return data;
};

const storeData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export { processCsv };