<template>
  <div class="window">
  <div class="title-bar">
    <button aria-label="Close" class="close"></button>
    <h1 class="title">SUPERMEI</h1>
    <button aria-label="Resize" class="resize"></button>
  </div>
  <div class="separator"></div>
  <div class="window-pane">
    <button class="btn" @click="loginWithGoogle">Acessar com conta Sensu</button>
  </div>
</div>
</template>

<script>
import { auth } from '../firebaseConfig';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'vue-router';

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  hd: 'faculdadesensu.edu.br'
});

export default {
  name: 'LoginPage',
  methods: {
    async loginWithGoogle() {
      try {
        await signInWithPopup(auth, googleProvider);
      } catch (error) {
        console.error('Erro ao autenticar:', error.message);
      }
    }
  },
  mounted() {
    const router = useRouter();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Usuário autenticado:', user.displayName);
        localStorage.setItem('displayName', user.displayName);
        router.push('/dashboard');
      }
    });
  }
};
</script>

<style scoped>
.window {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}
</style>