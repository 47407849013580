<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { auth, googleProvider } from '@/firebaseConfig';
import { signInWithPopup } from 'firebase/auth';

export default {
  name: 'LoginPage',
  methods: {
    async loginWithGoogle() {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        console.log('User logged in:', result.user);
      } catch (error) {
        console.error('Login failed:', error);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;}
</style>