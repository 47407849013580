<template>
  <div class="window">
    <div class="title-bar">
      <button aria-label="Close" class="close"></button>
      <h1 class="title">Dados Importados</h1>
      <button aria-label="Resize" class="resize"></button>
    </div>
    <div class="table-container">
      <table v-if="csvData.length > 0" class="custom-table">
        <thead>
          <tr>
            <th v-for="(value, key) in csvData[0]" :key="key">{{ key }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in csvData" :key="index">
            <td v-for="(value, key) in entry" :key="key">{{ value }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <p>Nenhum dado CSV carregado ainda.</p>
      </div>
    </div>
    <!-- Botão para navegar para a página de edição -->
    <section class="field-row" style="justify-content: flex-end">
      <button class="btn" @click="goToEditorPage(0)">Editar</button>
      <button class="btn" @click="downloadCSV">Baixar CSV</button>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SendPage',
  data() {
    return {
      csvData: []
    };
  },
  mounted() {
    const fileName = this.$route.params.fileName;
    const csvDataString = localStorage.getItem(fileName);

    if (csvDataString) {
      this.csvData = JSON.parse(csvDataString);
    } else {
      console.warn(`Nenhum dado encontrado para o arquivo ${fileName} no localStorage.`);
    }
  },
  methods: {
    goToEditorPage(index) {
      const fileName = this.$route.params.fileName;
      this.$router.push({ name: 'editor', params: { fileName: fileName, entryIndex: index } });
    },
    downloadCSV() {
      if (this.csvData.length === 0) {
        console.warn("Não há dados para exportar.");
        return;
      }

      // Mapeia os dados para ajustar o formato do nome e excluir fullname
      const mappedData = this.csvData.map(entry => {
        const { fullname, ...rest } = entry; // Desestrutura o objeto, removendo fullname

        // Quebra o fullname em firstname e lastname, se necessário
        const spaceIndex = fullname ? fullname.indexOf(' ') : -1;
        const firstname = spaceIndex !== -1 ? fullname.substring(0, spaceIndex) : fullname;
        const lastname = spaceIndex !== -1 ? fullname.substring(spaceIndex + 1) : '';

        // Retorna um novo objeto com os campos ajustados e fullname removido
        return {
          ...rest, // Inclui todos os outros campos exceto fullname
          firstname: firstname,
          lastname: lastname
        };
      });

      // Geração do conteúdo CSV
      const headers = Object.keys(mappedData[0]);
      let csvContent = headers.join(',') + '\n';

      mappedData.forEach(entry => {
        const row = headers.map(header => entry[header]).join(',');
        csvContent += `${row}\n`;
      });

      // Criação do arquivo CSV e download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${this.$route.params.fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>


<style scoped>

.window {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.btn {
  margin-top: 20px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}
</style>